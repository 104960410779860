import { useContext, useRef } from "react";
import { AppContext } from "../App";
import Label from "./Label";
import Api from "./api";

function Form() {
	const { reservation, order, vote, setVote, setVoted, mode } = useContext(AppContext);
	const message = useRef();

	const handleForm = async () => {
		if (mode == "reservation") {
			const response = await Api.post("/reviews/vote_reservation/" + reservation.id + "/", {
				review: vote,
				review_message: message.current.value,
			});
			if (response.success == 0) alert(response.error);
		} else {
			const response = await Api.post("/reviews/vote_order/" + order.id + "/", {
				review: vote,
				review_message: message.current.value,
			});
			if (response.success == 0) alert(response.error);
		}
		setVoted(true);
	};

	return (
		<div className="form">
			<textarea ref={message} required></textarea>
			<button onClick={handleForm}>
				<Label number={7} />
			</button>
			<button onClick={() => setVote(false)} className="secondary">
				<Label number={10} />
			</button>
		</div>
	);
}

export default Form;
